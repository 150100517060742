@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
	--bs-blue: #118dfa;
	--bs-indigo: #387ac6;
	--bs-purple: #1566ad;
	--bs-pink: #d63384;
	--bs-red: #db2a12;
	--bs-orange: #ffb600;
	--bs-ore: #ffab00;
	--bs-yellow: #fece2f;
	--bs-green: #198754;
	--bs-teal: #8fe1a1;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #79898c;
	--bs-gray-dark: #313c40;
	--bs-gray-100: #fafafa;
	--bs-gray-200: #f7f7f7;
	--bs-gray-300: #e5e5e5;
	--bs-gray-400: #ced4da;
	--bs-gray-500: #adb5bd;
	--bs-gray-501: #afafaf;
	--bs-gray-600: #707070;
	--bs-gray-700: #475459;
	--bs-gray-800: #3c383a;
	--bs-gray-900: #313c40;
	--bs-primary: #118dfa;
	--bs-secondary: #ffb600;
	--bs-success: #8fe1a1;
	--bs-info: #198754;
	--bs-warning: #fece2f;
	--bs-danger: #db2a12;
	--bs-light: #f2f2f2;
	--bs-dark: #1e191b;
	--bs-primary-rgb: 17, 141, 250;
	--bs-secondary-rgb: 255, 182, 0;
	--bs-success-rgb: 143, 255, 161;
	--bs-info-rgb: 13, 202, 240;
	--bs-warning-rgb: 254, 206, 47;
	--bs-danger-rgb: 219, 42, 18;
	--bs-light-rgb: 242, 242, 242;
	--bs-dark-rgb: 30, 25, 27;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-body-color-rgb: 33, 37, 41;
	--bs-body-bg-rgb: 255, 255, 255;
	--bs-body-font-family: 'Inter', sans-serif;
	--bs-body-font-size: 1rem;
	font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.5;
	--bs-body-color: #79898c;
	--bs-body-bg: #fff;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: var(--bs-gray-700);
	font-weight: 600;
}

a {
	color: var(--bs-gray-700);
}

.h1,
h1 {
	font-size: 1.625rem;
	line-height: 1.875rem;
}

.h2,
h2 {
	font-size: 21px;
	line-height: 25px;
}

.h3,
h3 {
	font-size: 18px;
	line-height: 21px;
}


/*Layout Classes*/
.wrapper {
	display: flex;
	flex-direction: column;
	flex: 0 0 1;
	min-height: 100vh;
}

.page-wrapper {
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.page-wrapper--dashboard {
	/*background: rgba(233, 241, 242, 0.16);*/
	background: var(--bs-gray-100);
}

.main {
	display: flex;
	align-items: stretch;
}

.header {
	background: var(--bs-white);
}

.mycssmargin {
	margin-top: 30px;
}

.header .container {
	max-width: 96%;
	width: 100%;
}

#chevroner {
	background-image: url('../src/assets/img/background.png') center auto no-repeat;
	/* background:  url("../src/assets/img/background.png") center auto no-repeat; */
}

.chevron {
	position: relative;
	text-align: center;
	padding: 12px;
	margin-bottom: 6px;
	height: 30px;
	width: 30px;
	left: 0;
	transform: rotate(-90deg);
}

.chevron:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 51%;
	background: red;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
		rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	transform: skew(0deg, 30deg);
}

.chevron:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 50%;
	background: red;
	transform: skew(0deg, -30deg);
}

.borderslide {
	border: 3px solid grey;
	margin-bottom: 50px;
	margin-top: 50px;
}

.dash-main {
	margin-top: 70px;
	padding: 30px;
	display: flex;
	flex-direction: column;
	background: #F5F5F9;
	min-height: calc(100vh - 70px);
	/*margin-left: 270px;*/
}

.dash-inner-page {
	flex: 1 1 auto;
}

.page-wrapper--dashboard.show-sidebar .dash-main {
	margin-left: 270px;
}

@media screen and (max-width: 1023.99px) {

	.dash-main,
	.page-wrapper--dashboard.show-sidebar .dash-main {
		margin-left: 0;
		padding: 20px 4px;
	}
}

@media screen and (max-width: 767px) {
	.dash-main {
		padding: 30px 10px;
	}
}

.list-group {
	border-radius: 0px !important;
}

.ant-menu-submenu-arrow {
	transform: rotate(270deg) translateX(2.5px) !important;
}

.ant-menu-submenu-arrow::before {
	transform: rotate(180deg) translateX(2.5px);
}

.ant-menu-submenu-arrow::after {
	transform: rotate(180deg) translateX(2.5px);
}

.ant-menu-item-active {
	background: var(--bs-dark) !important;
	border-radius: 0px !important;
	color: var(--bs-white) !important;
	height: 47px !important;
	padding: 1px 0px 5px;
}

.ant-menu-item-selected {
	background: var(--bs-dark) !important;
	border-radius: 0px !important;
	color: var(--bs-white) !important;
	height: 47px !important;
	padding: 1px 0px 5px;
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
	/* background: var(--bs-dark) !important; */
	border-radius: 0px !important;
	color: var(--bs-dark) !important;
	height: 47px !important;
	padding: 0px 20px 0px 32px !important;
}

.active {
	background: var(--bs-dark) !important;
	color: var(--bs-white) !important;
}

.active .MuiSvgIcon-root {
	color: var(--bs-ore) !important;
}

.active .sidebar__link {
	background: var(--bs-dark) !important;
	color: var(--bs-white) !important;
}


.ant-menu-item {
	color: #222;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 23px;
	/* 153.333% */
}

.sidebarWrapper {
	min-height: calc(100vh - 70px);
	background-color: #ffffff;
	position: fixed;
	top: 67px;
	left: 0;
	z-index: 1029;
	width: 270px;
	padding: 30px 0px 15px 0px;
	display: flex;
	flex-direction: column;
	bottom: 0;
}

.sidebar {
	flex-grow: 1;
	overflow-y: auto;
	margin-right: 0;
	padding-right: 0;
}

.sidebar__li {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: none;
	padding: 8px;
	background: var(--bs-white);
	margin-bottom: 4px;
	border-radius: 0px;
}

.sidebar__link {
	text-decoration: none;
	font-weight: 500;
	font-size: 15px;
	line-height: 40px;
}

.sidebar__li.active,
.sidebar__li.active:hover {
	background: var(--bs-dark);
}

.sidebar__li.active .sidebar__link,
.sidebar__li.active:hover .sidebar__link {
	color: var(--bs-white);
}

.sidebar__li:hover {
	background: var(--bs-gray-200);
	/*color: var(--bs-gray-dark);*/
}

.sidebar__li:hover .sidebar__link {
	color: var(--bs-gray-dark);
}

.sidebar__li.active .sidebar__icon,
.sidebar__li:hover .sidebar__icon {
	fill: #0dcaf0;
	path: white;
}

.sidebar__icon {
	margin-right: 15px;
}

.sidebar-footer {
	/*position: absolute;*/
	/*bottom: 30px;*/
}

.mobileSidebarBtn {
	position: absolute;
	top: 20px;
	right: -30px;
	min-width: auto;
	border-radius: 50%;
	font-size: 12px;
	width: 40px;
	height: 40px;
	display: none;
	align-items: center;
	justify-content: center;
}

.mobileSidebarBtn img {
	transform: rotate(-90deg);
	transition: transform 0.5s ease-in-out;
}

.mobileSidebarBtn.active img {
	transform: rotate(90deg);
	transition: transform 0.5s ease-in-out;
}

@media screen and (max-width: 1023.99px) {
	.mobileSidebarBtn {
		display: inline-flex;
	}

	.sidebarWrapper {
		/*display: none;*/
		left: -270px;
		transition: left 0.5s ease-in;
	}

	.sidebarWrapper.for-mobile {
		left: 0;
		transition: left 0.5s ease-in-out;
	}

	.sidebar__li {
		padding: 12px 16px;
	}

	.sidebar__link {
		font-size: 16px;
		line-height: 18px;
	}

	.sidebar-footer {
		position: absolute;
		bottom: 30%;
	}
}


.dashedBtn {
	width: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: 1px dashed var(--bs-gray-300) !important;
}

.dashBorderCard {
	border-radius: 6px;
	/*padding: 2px 10px;*/
	color: var(--bs-dark);
	display: block;
	border: 1px dashed var(--bs-gray-300);
}

a.dashBorderCard {
	text-decoration: none;
}

a.dashBorderCard:hover {
	color: var(--bs-white);
}

.commonTable {
	background: white;
	border-radius: 6px;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
	--bs-table-accent-bg: white;
	color: var(--bs-gray-800);
}

.commonTable thead tr th {
	background: #e7e4e490;
	color: #222;
	font-weight: 500;
	font-size: 16px;
}

.commonTable th,
.commonTable td {
	padding: 15px 20px;
	border-bottom: 1px solid var(--bs-gray-200);
}

.commonTable thead tr th:first-child {
	border-top-left-radius: 6px;
}

.commonTable thead tr th:last-child {
	border-top-right-radius: 6px;
}

.commonTable tr:last-child td:first-child {
	border-bottom-left-radius: 6px;
}

.commonTable tr:last-child td:last-child {
	border-bottom-right-radius: 6px;
}

.withdrawDetails {
	background: var(--bs-white);
	padding: 30px;
}

.withdrawDetails__header,
.withdrawDetails__body {
	border-bottom: 1px solid var(--bs-gray-200);
	margin-bottom: 30px;
}

.withdrawDetails__body {
	padding-bottom: 30px;
}

.earningDiv {
	position: relative;
}

.earningDiv:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0;
	width: 4px;
	height: 100%;
	border-left: 1px solid var(--bs-gray-300);
	border-right: 1px solid var(--bs-gray-300);
}

.notificationBell {
	position: relative;
	display: inline-flex;
}

.notificationBell .dot {
	position: absolute;
	width: 10px;
	height: 10px;
	background: #feab29;
	right: -5px;
	border-radius: 50%;
}

.notificationDropDown .dropdown-toggle::after,
.userDropDown .dropdown-toggle::after {
	content: none;
}

/*landing page*/
.landing-page {
	display: flex;
	flex-direction: column;
}

.landing-page .container-fluid {
	width: 95%;
}

.landing-main {
	flex-grow: 1;
	overflow-y: auto;
	bottom: 0;
}


html {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	-ms-font-smoothing: antialiased !important;
}

.md-stepper-horizontal {
	display: table;
	width: 100%;
	margin: 0 auto;
	/* box-shadow: 0 3px 8px -6px rgba(0,0,0,.50); */
}

.md-stepper-horizontal .md-step {
	display: table-cell;
	position: relative;
	padding: 24px;
}

/* .md-stepper-horizontal .md-step:hover, */
.md-stepper-horizontal .md-step:active {
	background-color: rgba(0, 0, 0, 0.04);
}

.md-stepper-horizontal .md-step:active {
	border-radius: 15% / 75%;
}

.md-stepper-horizontal .md-step:first-child:active {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.md-stepper-horizontal .md-step:last-child:active {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

/* .md-stepper-horizontal .md-step:hover .md-step-circle {
	background-color:#757575;
} */
.md-stepper-horizontal .md-step:first-child .md-step-bar-left,
.md-stepper-horizontal .md-step:last-child .md-step-bar-right {
	display: none;
}

.md-stepper-horizontal .md-step .md-step-circle {
	width: 30px;
	height: 30px;
	margin: 0 auto;
	background-color: #999999;
	border-radius: 50%;
	text-align: center;
	line-height: 30px;
	font-size: 16px;
	font-weight: 600;
	color: #ffffff;
}

.md-stepper-horizontal.green .md-step.active .md-step-circle {
	background-color: #00ae4d;
}

.md-stepper-horizontal.orange .md-step.active .md-step-circle {
	background-color: #f96302;
}

.md-stepper-horizontal .md-step.active .md-step-circle {
	background-color: rgb(33, 150, 243);
}

.md-stepper-horizontal .md-step.done .md-step-circle:before {
	font-weight: 100;
	content: '\f00c';
}

.md-stepper-horizontal .md-step.done .md-step-circle *,
.md-stepper-horizontal .md-step.editable .md-step-circle * {
	display: none;
}

.md-stepper-horizontal .md-step.editable .md-step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.md-stepper-horizontal .md-step.editable .md-step-circle:before {
	font-weight: 100;
	content: '\f040';
}

.md-stepper-horizontal .md-step .md-step-title {
	margin-top: 16px;
	font-size: 16px;
	font-weight: 600;
}

.md-stepper-horizontal .md-step .md-step-title,
.md-stepper-horizontal .md-step .md-step-optional {
	text-align: center;
	color: rgba(0, 0, 0, 0.26);
}

.md-stepper-horizontal .md-step.active .md-step-title {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.87);
}

.md-stepper-horizontal .md-step.active.done .md-step-title,
.md-stepper-horizontal .md-step.active.editable .md-step-title {
	font-weight: 600;
}

.md-stepper-horizontal .md-step .md-step-optional {
	font-size: 12px;
}

.md-stepper-horizontal .md-step.active .md-step-optional {
	color: rgba(0, 0, 0, 0.54);
}

.md-stepper-horizontal .md-step .md-step-bar-left,
.md-stepper-horizontal .md-step .md-step-bar-right {
	position: absolute;
	top: 80px;
	height: 2px;
	border-top: 1px solid #dddddd;
}

.md-steps {
	height: 2px;
	border-bottom: 2px dotted #dddddd;
}

.md-stepper-horizontal .md-step .md-step-bar-right {
	right: 0;
	left: 50%;
	margin-left: 20px;
}

.md-stepper-horizontal .md-step .md-step-bar-left {
	left: 0;
	right: 50%;
	margin-right: 20px;
}

.commonSection {
	padding: 50px 0;
	position: relative;
}

.uncommonSection {
	position: relative;
}

.easycommonSection {
	padding-top: 40px;
	padding-bottom: 40px;
	background: linear-gradient(to right,
			var(--bs-light) 100%,
			var(--bs-white) 100%);
}

.there {
	margin-bottom: 50px;
}

.landing-h8h {
	margin-top: -50px;
	margin-bottom: -50px;
}

.landing-h1 {
	font-size: 50px;
	line-height: 62px;
	font-weight: 700;
	color: #000000;
}

.banger {
	margin-top: 10%;
}

.integs {
	padding-top: 50px;
}

.landing-h2 {
	font-size: 50px;
	line-height: 62px;
	font-weight: 700;
	color: var(--bs-dark);
}

.marginal {
	margin-top: -50px;
}

.landing-h3 {
	font-size: 30px;
	line-height: 34px;
	font-weight: 600;
	color: var(--bs-gray-dark);
}

.landing-h7h {
	font-size: 30px;
	line-height: 34px;
	font-weight: 600;
	margin-top: 20px;
	margin-bottom: 20px;

	color: var(--bs-gray-dark);
}

.merger {
	margin-bottom: 20px;
	min-height: 250px;
}

.landing-h78 {
	margin: 5px;
}

.landing-h4 {
	font-size: 20px;
	line-height: 24px;
	font-weight: 500;
}

.landing-h4p {
	font-size: 20px;
	line-height: 24px;
	font-weight: 400;
	color: var(--bs-gray-900);
}

.landingHeader.navbar-light .nav-link {
	font-size: 20px;
	line-height: 22px;
	font-weight: 500;
	color: var(--bs-gray-700);
}

.landingHeader.navbar-light .nav-link:hover,
.landingHeader.navbar-light .nav-link.active {
	color: var(--bs-red);
}

.navlinks .nav-link {
	padding: 8px 30px;
}

.landingHeader .btn {
	font-size: 18px;
	width: 150px;
	border-radius: 5px;
	line-height: 21px;
	padding: 10px 24px;
	font-weight: 600;
}

.landing-page .btn-lg {
	border-radius: 5px;
	padding: 16px 24px;
	font-weight: 600;
	font-size: 20px;
	line-height: 24px;
	min-width: 180px;
}

.landing-banner {
	padding-top: 100px;
	padding-bottom: 30px;
}

.landing-bannerm {
	background-color: var(--bs-light);
	padding-top: 150px;
	padding-bottom: 30px;
}

.mb-margin {
	margin-left: 10px;
	margin-right: 10px;
}

.landing-banner .landing-h2 {
	color: var(--bs-red);
	font-weight: 600;
	margin-top: 20px;
	margin-bottom: 30px;
}

.landing-banner .landing-h3 {
	color: var(--bs-gray-700);
	font-weight: normal;
	font-size: 45px;
	line-height: 50px;
	margin-bottom: 50px;
}

.landing-banner-about {
	background: url('./assets/img/aboutBanner.png') no-repeat center;
	background-size: cover;
	padding-bottom: 30px;
	padding-top: 250px;
}

.landing-banner-light {
	background-color: var(--bs-light);
	padding-bottom: 30px;
	padding-top: 250px;
}

.landing-banner-about .landing-h2 {
	color: var(--bs-white);
	font-weight: bold;
}

.landing-banner-light .landing-h2 {
	color: var(--bs-dark);
	font-weight: bold;
}

.section-mb0 .helpSection {
	margin-bottom: 0 !important;
}

.sellingSecting {
	/*position: relative;*/
	padding-bottom: 0;
}

.sellingSecting img {
	position: relative;
	z-index: 1;
}

.sellingSecting .btn {
	margin-top: 100px;
}

.developerlist {
	align-items: center;
	margin-top: 40px;
}

.developerlist li {
	list-style-type: none;
	counter-increment: item;
}

.developerlist li:before {
	content: counter(item);
	margin-right: 5px;
	font-size: 80%;
	background-color: #f96302;
	color: #ffffff;
	font-weight: bold;
	padding: 3px 8px;
	border-radius: 3px;
}

.sellingSecting:after {
	position: absolute;
	bottom: 0;
	content: '';
	width: 100%;
	height: 170px;
	background: var(--bs-white);
}

.gradientSection {
	background: linear-gradient(to right,
			var(--bs-light) 50%,
			var(--bs-white) 50%);
}

.accountSection__DetailLeft p,
.accountSection__DetailRight p {
	margin-bottom: 4px;
}

.accountSection__DetailLeft,
.accountSection__DetailRight {
	padding: 60px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.accountSection__DetailLeft {
	padding-left: 12px;
}

.accountSection__DetailRight {
	padding-right: 12px;
}

.donwloadSectionImg {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	background: url('./assets/img/downloadSectionImg.svg') no-repeat center;
	background-size: cover;
	min-height: 440px;
}

.helpSection .leading-h2 {
	font-size: 40px;
	line-height: 47px;
}

.helpSection a {
	text-decoration: none;
	font-weight: 600;
	color: var(--bs-dark);
	font-size: 18px;
	line-height: 20px;
	display: inline-flex;
	align-items: center;
}

.helpSection .list-inline-item:not(:last-child) {
	margin-right: 80px;
}

.faq {
	color: #171a1f;
	font-size: 46px;
	font-style: normal;
	font-weight: 700;
	line-height: 64px;
	text-align: center;
}

.commonAccording .accordion-item {
	margin-bottom: 16px;
	border: 0;
}

.commonAccording {
	width: 70vw;
}

.commonAccording .accordion-button {
	background-color: #e9f1f2;
	border-radius: 5px;
	box-shadow: none;
	color: var(--bs-gray-dark);
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
	padding: 20px 30px;
}

.collapse {
	visibility: visible !important;
}

.commonAccording .accordion-button:not(.collapsed) {
	background-color: #8fe1a1;
	box-shadow: none;
}

.commonAccording .accordion-body {
	font-size: 16px;
	padding: 16px 30px;
}

.commonAccording .accordion-button::after {
	/*background-image: url("./assets/img/arrowDown.svg");*/
	background-image: url('data:image/svg+xml,%3Csvg%20id%3D%22Group_233%22%20data-name%3D%22Group%20233%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%20%20%3Cpath%20id%3D%22Path_159%22%20data-name%3D%22Path%20159%22%20d%3D%22M0%2C0H24V24H0Z%22%20fill%3D%22none%22%2F%3E%0A%20%20%3Cpath%20id%3D%22Path_160%22%20data-name%3D%22Path%20160%22%20d%3D%22M7%2C7l5%2C5%2C5-5%22%20fill%3D%22none%22%20stroke%3D%22%2379898c%22%20strokeLinecap%3D%22round%22%20strokeLinejoin%3D%22round%22%20strokeWidth%3D%222%22%2F%3E%0A%20%20%3Cpath%20id%3D%22Path_161%22%20data-name%3D%22Path%20161%22%20d%3D%22M7%2C13l5%2C5%2C5-5%22%20fill%3D%22none%22%20stroke%3D%22%2379898c%22%20strokeLinecap%3D%22round%22%20strokeLinejoin%3D%22round%22%20strokeWidth%3D%222%22%2F%3E%0A%3C%2Fsvg%3E%0A');
}

.commonAccording .accordion-button:not(.collapsed)::after {
	background-image: url('data:image/svg+xml,%3Csvg%20id%3D%22Group_233%22%20data-name%3D%22Group%20233%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%0A%20%20%3Cpath%20id%3D%22Path_159%22%20data-name%3D%22Path%20159%22%20d%3D%22M0%2C0H24V24H0Z%22%20fill%3D%22none%22%2F%3E%0A%20%20%3Cpath%20id%3D%22Path_160%22%20data-name%3D%22Path%20160%22%20d%3D%22M7%2C7l5%2C5%2C5-5%22%20fill%3D%22none%22%20stroke%3D%22%23313C40%22%20strokeLinecap%3D%22round%22%20strokeLinejoin%3D%22round%22%20strokeWidth%3D%222%22%2F%3E%0A%20%20%3Cpath%20id%3D%22Path_161%22%20data-name%3D%22Path%20161%22%20d%3D%22M7%2C13l5%2C5%2C5-5%22%20fill%3D%22none%22%20stroke%3D%22%23313C40%22%20strokeLinecap%3D%22round%22%20strokeLinejoin%3D%22round%22%20strokeWidth%3D%222%22%2F%3E%0A%3C%2Fsvg%3E%0A');
}

.footerSection {
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	padding-bottom: 30px;
}

.footerSection .nav-link {
	color: #cacaca;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
}

.footerSection .socialLinks .list-inline-item:not(:last-child) {
	margin-right: 80px;
}

.footerSection .list-unstyled {
	width: 250px;
	margin-left: auto;
}

.solutionSection .landing-h2 {
	margin-bottom: 100px;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: none;
	border: 2px solid var(--bs-danger) !important;
}

.padders {
	margin-top: 30px;
	margin-bottom: 200px;
}

.redder {
	color: #ff0000;
}

.padders1 {
	margin-top: 60px;
	margin-bottom: 200px;
}

.formar {
	margin-top: 5px;
	margin-bottom: 5px;
	box-shadow: none;
}

.formarter {
	margin-top: -90px;
	margin-bottom: 5px;
}

.thier {
	margin-bottom: 70px;
}

@media screen and (min-width: 1400px) {
	.footerSection .container {
		max-width: 1140px;
	}
}

@media screen and (max-width: 1399.99px) {
	.sellingSecting:after {
		height: 100px;
	}

	.earningDiv:after {
		content: none;
	}
}

@media screen and (max-width: 1199.99px) {}

@media screen and (max-width: 1023.99px) {

	.commonTabs .nav-item,
	.commonTabs>.nav-link {
		flex: 1 1 50%;
		text-align: center;
	}

	.commonTabs .nav-item .nav-link {
		width: 100%;
	}

	.gradientSection {
		background: linear-gradient(to bottom,
				var(--bs-light) 50%,
				var(--bs-white) 50%);
	}

	.accountSection__DetailLeft,
	.accountSection__DetailRight {
		padding: 30px 12px;
	}
}

@media screen and (max-width: 991.99px) {
	.footerSection .list-unstyled {
		width: 100%;
		margin-left: auto;
		text-align: center;
		margin-top: 16px;
	}

	.landing-h2 {
		font-size: 40px;
		line-height: 44px;
	}

	.landing-h3 {
		font-size: 25px;
		line-height: 28px;
	}

	.landing-banner .landing-h3 {
		font-size: 35px;
		line-height: 40px;
	}

	.donwloadSectionImg {
		border-top-left-radius: 6px;
		border-bottom-left-radius: 0;
		border-top-right-radius: 6px;
		min-height: 200px;
	}

	.landing-banner {
		padding-top: 150px;
		padding-bottom: 30px;
	}

	.sellingSecting .row {
		/*flex-direction: column-reverse;*/
	}

	.sellingSecting .btn {
		margin-top: 50px;
	}

	.commonAccording .accordion-body {
		padding: 16px;
	}

	.commonAccording .accordion-button {
		padding: 20px 16px;
	}

	.sellingSecting:after {
		content: none;
	}

	.accountSection__DetailRight {
		padding-bottom: 0 !important;
	}

	.accountSection__DetailLeft {
		padding-top: 0 !important;
	}

	.solutionSection .landing-h2 {
		margin-bottom: 50px;
	}

	.landingHeader {
		background-color: var(--bs-light);
	}

	.account-profiles-side {
		padding: 16px;
	}

	.account-tabs-side {
		margin-left: 0;
		padding-left: 12px;
	}

	.withdrawDetails {
		padding: 16px;
	}

	.sidebarWrapper {
		padding: 20px 16px 16px 16px;
	}
}

.react-tel-input .form-control {
	position: relative;
	font-size: 14px;
	letter-spacing: .01rem;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-left: 48px;
	margin-left: 0;
	background: #FFFFFF;
	border: 1px solid #CACACA;
	border-radius: none !important;
	line-height: 25px;
	height: 45px !important;
	width: 100% !important;
	outline: none;
}


.table-heads {
	color: var(--neutral-100, #0A0A0A);
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	/* 150% */
	letter-spacing: -0.34p
}

.table-sub-heads {
	color: #222;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
}

.table-filter-txt {
	color: #000;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.org-info-text {
	color: #0A0A0A;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	letter-spacing: -0.084px;
}

.ant-drawer-header-title {
	flex-direction: row-reverse;
}

.ant-modal-content {
	padding: 0 !important;
	z-index: 1000;
}

.ant-modal-body {
	padding: 20px 17px !important;
}

.ant-modal-close-x {
	color: white !important;
	font-weight: 500;
}

.ant-modal-title {
	background: black;
	color: white !important;
	padding: 20px 17px;
	font-size: 22px;
	font-weight: 500;
	letter-spacing: -0.462px;
}

.ant-select-selector {
	height: 44px !important;
	padding: 4px !important;
}

.ant-modal-footer {
	padding: 20px !important;
}

.ant-steps {
	display: none !important;
}

.ant-input-number-input {
	height: 42px !important;
}

.ant-input-number-handler-wrap {
	display: none !important;
}

.ant-upload-icon>.anticon,
.ant-upload-list-item-name {
	color: #0A0A0A !important;
}

.ant-tabs-tab {
	background-color: #fff !important;
}

.ant-tabs-tab-active {
	background-color: var(--bs-dark) !important;
	color: var(--bs-orange) !important;
}

.ant-tabs-tab-active span {
	color: var(--bs-orange) !important;
	padding-left: 5px !important;
	padding-right: 5px !important;
}

.loading-state {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .loading {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	border: 10px solid #ddd;
	border-top-color: orange;
	animation: loading 1s linear infinite;
  }
  @keyframes loading {
	to {
	  transform: rotate(360deg);
	}
  }
  