@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

.home__tabs {
	@apply p-3 border-2 border-[#000] w-full text-center !no-underline !text-[#000];
}

.home__tabs_active {
	@apply p-3 border-2 border-[#000] bg-[#000] w-full text-center !text-white !no-underline;
}

.nav-item {
	@apply !text-[#1E2432]  text-[14px] font-[400];
	font-family: 'Plus Jakarta Sans', sans-serif;
}

.meet {
	color: #1e2432;
	font-family: 'Plus Jakarta Sans', sans-serif;
	font-size: 38px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
}

.with {
	color: #475569;
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.make {
	color: #017efa;
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.16px;
}

.make2 {
	color: #1e2432;

	/* H2/Semibold */
	font-family: Plus Jakarta Sans;
	font-size: 38px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%; /* 53.2px */
}

.ndov {
	color: #475569;

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.tick {
	color: #475569;

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.read {
	color: #017efa;

	/* Text/16/SemiBold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.16px;
}

.collections {
	color: #ffb600;

	/* Text/16/SemiBold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.16px;
}

.manage {
	color: #fff;
	font-family: Plus Jakarta Sans;
	font-size: 38px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.ol {
	color: #fff;

	/* Text/16/Bold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.ol-span {
	color: #fff;

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.why {
	color: #212529;

	/* Text/16/SemiBold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.16px;
}

.faster {
	color: var(--neutral-100, #1e2432);

	/* H2/Semibold */
	font-family: Plus Jakarta Sans;
	font-size: 38px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%; /* 53.2px */
}

.fast-p {
	color: var(--neutral-100, #1e2432);

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.btn-home-yellow {
	color: var(--NdovuPay-Black, #212529);

	/* Text/16/SemiBold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.16px;
}

.why-span {
	color: var(--neutral-100, #1e2432);

	/* Text/16/Bold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

.why-p {
	color: var(--neutral-100, #1e2432);

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.what {
	color: var(--NdovuPay-Yellow, #ffb600);

	/* Text/16/SemiBold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.16px;
}

.hear {
	color: var(--neutral-10, #fff);

	/* H2/Semibold */
	font-family: Plus Jakarta Sans;
	font-size: 38px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%; /* 53.2px */
}

.hear-p {
	color: #fff;

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.here {
	color: var(--neutral-100, #1e2432);

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.stay {
	color: var(--neutral-10, #fff);
	font-family: Plus Jakarta Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

.follow {
	color: var(--neutral-20, #f7f9fb);

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.f-header {
	color: var(--neutral-10, #fff);
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-bottom: 16px;
}

.f-body {
	color: var(--neutral-20, #f7f9fb);

	/* Text/14/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.all {
	color: #ffab00;
	font-family: Plus Jakarta Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 33.6px */
}

.lets {
	color: #1e2432;

	/* Text/16/SemiBold */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.16px;
}

.experience {
	color: var(--neutral-100, #1e2432);

	/* H2/Semibold */
	font-family: Plus Jakarta Sans;
	font-size: 38px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%; /* 53.2px */
}

.join {
	color: var(--neutral-100, #1e2432);

	/* Text/16/Regular */
	font-family: Plus Jakarta Sans;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 180%; /* 28.8px */
	letter-spacing: 0.128px;
}

.box {
	position: relative;
	background: #ffffff;
	width: 100%;
}

.box-header {
	color: #444;
	display: block;
	padding: 10px;
	position: relative;
	border-bottom: 1px solid #f4f4f4;
	margin-bottom: 10px;
}

.box-tools {
	position: absolute;
	right: 10px;
	top: 5px;
}

.dropzone-wrapper {
	border: 1px dashed #0873b9;
	color: #92b0b3;
	position: relative;
	height: 150px;
}

.dropzone-desc {
	position: absolute;
	margin: 0 auto;
	left: 0;
	right: 0;
	text-align: center;
	width: 40%;
	top: 35px;
	font-size: 16px;
}

.dropzone,
.dropzone:focus {
	position: absolute;
	outline: none !important;
	width: 100%;
	height: 150px;
	cursor: pointer;
	opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
	background: #ecf0f5;
}

.preview-zone {
	text-align: center;
}

.preview-zone .box {
	box-shadow: none;
	border-radius: 0;
	margin-bottom: 0;
}
